.customDropdown {
    display: inline-block;
    float: left;
    background-color: white !important;
    color: #636363 !important;
    height: 40px;
    padding: 10px 5px 10px 5px;
    margin-right: 10px;
    border-radius: 10px;
    border:1px solid #B9B9B9;
    z-index: 9999;
}