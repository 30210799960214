.suspendedMenu
{
    background-color: #ed7203 !important;
    border-color: #E27800;
    color:white;
    border-top: 0 solid;
    border-right: 0 solid transparent;
    border-bottom: 0;
    border-left: 0 solid transparent;
}
.suspendedMenu::after
{
    background-color: #ed7203 !important;
    border-color: #E27800;
    color:white;
    border-top: 0 solid;
    border-right: 0 solid transparent;
    border-bottom: 0;
    border-left: 0 solid transparent;
}