.PhoneInputInput
{
    border:0px;
}
.PhoneInputInput::after{
    outline:0px;
};
.PhoneInputInput::before{
    outline:0px;
};
.PhoneInputInput:focus{
    outline:0px;
}