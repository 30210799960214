.dropdownchange
{
margin:0px; 
background-color:white;
height: 100%;
width:100%;
display:flex;
justify-content:space-around;
align-items: center;
border-radius: 10px;
border: solid;
 border-color:#afb4b8 !important;
}
.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    outline:0px;   
}
.dropdownchange:focus{
        outline:0px;
}